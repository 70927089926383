import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom"
import DatePicker, {registerLocale, setDefaultLocale} from "react-datepicker";
import es from "date-fns/locale/es";
import ca from "date-fns/locale/ca";
import en from "date-fns/locale/en-GB";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { useSelector, useDispatch } from "react-redux";
import { Button as ButtonSemantic,Form} from "semantic-ui-react";
import { Button } from "../../components/Button/Button";
import { useTranslation } from "react-i18next";
import Layout from "../Layout/LayoutContainer";
import DropDownAreas from "../DropDownAreas";
import DropDownServices from "../DropDownServices";
import InputRecording from "../InputRecording";
import BOTableMeeting from "../ServicesDetails/BOServiceMeeting/BOTableMeeting";
import { formType } from "./types";
import { CONTENT_MEETING_SUB_TYPE, CONTENT_MEETING_TYPE } from "../ServicesDetails/BOServiceMeeting/BOTableMeeting/components/BOTableMeeting/type/contentMeeting";
import { AuthContext } from "../../context/AuthContext";
import moment from "moment";
import 'moment/locale/ca';
import i18next from "i18next";
import {exportCSV, fetchMyAreasAdmin} from "../../assets/api";
import { ExportToCsv } from "export-to-csv";
import { stringify } from "querystring";
import { propTypes } from "react-bootstrap/esm/Image";
import { toast } from "react-toastify";
import meetings from "../../assets/meetings";
import Field from "../../components/Field/Field";
import { defaultAreaInfo } from "../../models/AreaInfo";
import { Area } from "../../types/area";
import { getAreasServicesApi, getServices } from "../../assets/areas";
import { Service } from "../../models/Service";
import { getLanguageNavigator } from "../../util/languages";
import { FaCalendarAlt } from 'react-icons/fa';
import { FontSizes } from "office-ui-fabric-react";


//import AccessibleIcon from "@mui/icons-material/Accessible";
//import { DatePicker } from "office-ui-fabric-react";

registerLocale("ca", ca);
registerLocale("es", es);
registerLocale("en", en);



type areaLang = {
  displayName: string;
  code: string;
};

interface Props {
  initialHistory: any;
  form: formType;
  setForm: (x: formType) => void;
  refreshMeeting: () => void;
  onHandleClickSearch: () => void;
  historyHandleClick: () => void;
  isButtonClicked: boolean;
  meetings: any;
  area?: any;
}

const Screen = (props: Props) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const [areas, setAreas] = useState<Area[]>([defaultAreaInfo]);
  const [services, setServices] = useState<Service[]>([]);
  const [isSearchButton, setSearchButton] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const location = useLocation();
  /*const language = i18next.language;
  console.log("LLENGUA:", language);
  moment.locale(language);
  console.log("MOMENT:", moment.locale(language));*/

  const changeSearchButton = async() => {
    const { pathname } = location;

    if(props.form.dateStart.length === 0 || props.form.dateEnd.length === 0){
      toast(t("area.meeting_search_date_form_error"), { type: "error"})
    }else{
      if(pathname.includes('area')){
        if(props.form.area.length === 0)
          toast(t("area.meeting_search_form_error"), { type: "error"})
      }

      await props.onHandleClickSearch.call(props.refreshMeeting);  
      props.refreshMeeting();
      setSearchButton(true);
    }    
  }

  useEffect(() => {
    if(props.initialHistory === "area"){
      getAreas();
  
      areas.map(area => {
        getServices(area.code);
      })
    }


  }, [])

  const getAreas = async () => {
    const { backoffice } = await authContext.getTokenForScopes();
    await fetchMyAreasAdmin(backoffice)
    .then(response => response.json())
    .then(response => setAreas(response));
  }

  const getServices = async (areaCode: string) => {
    const { backoffice } = await authContext.getTokenForScopes();

    getAreasServicesApi(areaCode, backoffice)
      .then(response => setServices(response))
  }

  const getCSVData = async () => {
    if (isSearchButton==true){
      var servicesWithMeetings:any[]=[];

      props.meetings.map((meeting:any)=>{
        let found:boolean = servicesWithMeetings.includes(meeting.serviceCode);
        if (found==false){servicesWithMeetings.push(meeting.serviceCode);}
      })

      const { backoffice } = await authContext.getTokenForScopes();
      let responses = await exportCSV(props.form, backoffice,servicesWithMeetings).then();
      const options = {
        fieldSeparator: ",",
        quoteStrings: '"',
        decimalSeparator: ".",
        showLabels: true,
        showTitle: true,
        title: t(`detail.meeting.csv.title`),
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: t(`detail.meeting.csv.title`),
      };
        const csvExporter = new ExportToCsv(options);
        var csvData:any[] = [];
        responses.map((response)=>{csvData = csvData.concat(response);})
        console.log(responses);
        let formatCsvData:any[]=[];
        csvData.map((csvDataService)=>{
            var entriesRecording = Object.entries(csvDataService).map(([key, value]: any) => {
              
              if ((key=="start") || (key=="end")){
                console.log("HEY IM INSIDE KEY IF")
                value = value.replace("T"," ");
              }
              else if(key=="status"){
                value = t(`detail.status.${value.toLowerCase()}`);
              }
                return [t(`detail.meeting.csv.${key}`), value];
            });
            formatCsvData.push(Object.fromEntries(entriesRecording));
        });
        console.log(formatCsvData);
        csvExporter.generateCsv(formatCsvData);
      }
  }

  const userCode = authContext?.account?.profile?.code;

  const contentMeeting = props.initialHistory === "area"
    ? [
      CONTENT_MEETING_TYPE.FULL_START,
      CONTENT_MEETING_TYPE.CODE,
      CONTENT_MEETING_TYPE.SERVICE_CODE,
      CONTENT_MEETING_TYPE.STATUS,
      CONTENT_MEETING_TYPE.ACTION,
    ]
    : [
      CONTENT_MEETING_TYPE.FULL_START,
      CONTENT_MEETING_TYPE.CODE,
      CONTENT_MEETING_TYPE.SERVICE_CODE,
      CONTENT_MEETING_TYPE.AREA_CODE,
      CONTENT_MEETING_TYPE.STATUS,
      CONTENT_MEETING_TYPE.ASSIGNED,
      CONTENT_MEETING_TYPE.createTypeAction([CONTENT_MEETING_SUB_TYPE.ACTION.SELF_ASSIGN, CONTENT_MEETING_SUB_TYPE.ACTION.REFUSE_SELF, CONTENT_MEETING_SUB_TYPE.ACTION.CANCEL_SELF]),
    ]

    const CustomInput = ({ value, onClick }) => (
      <div className="date-picker-input" onClick={onClick}>
        <span className="date-picker-value">{value}</span>
        <FaCalendarAlt className="date-picker-icon" />
        <style>
          {`
            .date-picker-input {
              display: flex;
              align-items: center;
              cursor: pointer;
              padding: 5px;
              border: 1px solid #ccc;
              border-radius: 4px;
              background-color: #fff;
              width: 200px
            }
    
            .date-picker-value {
              margin-right: 5px;
            }
    
            .date-picker-icon {
              margin-left: auto;
            }
            .react-datepicker {
              font-size: 1em;
            }
            
            .react-datepicker__month {
              margin: 0.4em 1em;
            }
            .react-datepicker__day-name, .react-datepicker__day {
              width: 1.9em;
              line-height: 1.9em;
              margin: 0.166em;
            }
            .react-datepicker__current-month {
              font-size: 1em;
            }
            
          `}
        </style>
      </div>
    );

  return (
    <>
      <Layout.Main>
        <h2>{t("area.search.title")}</h2>
        <div className="date-picker-container">
          <div className="date-picker-wrapper">
            <header>{t("recordings.start") + " *"}</header>
            <DatePicker
              selected={startDate}
              classNameContainer="w-50 mr-2"
              name="dateStart"
              onChange={(value) => {
                const date = new Date(value);
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                const formattedDate = `${year}-${month}-${day}`;
                console.log("FORMATTED", formattedDate);
                setStartDate(date);
                console.log("STARTDATE:", formattedDate);
                props.setForm({ ...props.form, dateStart: formattedDate })
              }}
              //todayButton="Hoy"
              showYearDropdown
              scrollableMonthYearDropdown
              dateFormat={'dd/MM/yyyy'}
              locale={getLanguageNavigator()}
              registerLocale
              value={props.form.dateStart}
              customInput={<CustomInput />}
            />
          </div>

          <div className="date-picker-wrapper">
            <header>{t("recordings.end") + " *"}</header>
            <DatePicker
              selected={endDate}
              classNameContainer="w-50"
              name="dateEnd"
              onChange={(value) => {
                const date = new Date(value);
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                const formattedDate = `${year}-${month}-${day}`;
                console.log("FORMATTED", formattedDate);
                setEndDate(date);
                console.log("ENDDATE:", formattedDate);
                props.setForm({ ...props.form, dateEnd: formattedDate })
              }}
              showYearDropdown
              scrollableMonthYearDropdown
              dateFormat={'dd/MM/yyyy'}
              locale={getLanguageNavigator()}
              registerLocale
              value={props.form.dateEnd}
              customInput={<CustomInput />}
            />
          </div>
        </div>
        <style>
        {`
          .date-picker-container {
            display: flex;
            
            align-items: flex-start;
          }

          .date-picker-wrapper {
            margin-right: 100px;
          }
        `}
      </style>
        
        <div className="d-flex flex-wrap flex-sm-nowrap mt-4">
          {props.initialHistory === "area" && 
          <>
            <DropDownAreas
              header={t("recordings.area") + " *"}
              areas={areas}
              classNameContainer="w-50"
              onChange={(area) => {
                props.setForm({ ...props.form, ["area"]: area, ["service"]: "" });
                getServices(area);
              }}
              />
              
              <DropDownServices
                services={services}
                header={t("recordings.service")}
                classNameContainer="w-100 mb-5"
                onChange={(service) => {
                  props.setForm({ ...props.form, ["service"]: service });
                }}
                multiple
              />
            </>
          }

          <button
              className="btn btn-danger mt-4"
              style={{ height: 50}}
              onClick={changeSearchButton}
          >
            {t("recordings.search")}
          </button>
        </div>
        
        {props.isButtonClicked && (
          <BOTableMeeting
            meetings={props.meetings}
            contentMeeting={contentMeeting}
            servicesRefresh={props.refreshMeeting}
            disabledMeeting={(({ assignedTo, start }) =>
              !(assignedTo === "" || assignedTo === userCode || assignedTo === "NotAssigned")
              || moment(start).diff(moment()) < 0
            )}
          />
        )}
      </Layout.Main>
      <Layout.Article>
        <Button
          message={t("go_back")}
          icon="left arrow"
          onClick={props.historyHandleClick}
        />
        {isSearchButton  && props.meetings.length>0?(
          <Button
          message= "Exportar dades"
          icon="download"
          onClick={getCSVData}
        />):(null)}
        
      </Layout.Article>
    </>
  );
};

export default Screen;
