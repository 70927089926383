import axios from 'axios';
import { loadAbort } from '../util/loadabort.utilities';

export const filterMeetingsPerAreaAndDate = (start: string, end: string, area: string, token: string) => {
    const controller = loadAbort();

    return {
        call: axios.get(
            `${process.env.REACT_APP_API_URL}/Events/filter/${start}/${end}/${area}`,
            {headers: {Authorization: `Bearer ${token}`}, signal: controller.signal}),
        controller
    }
}

export const deleteMeetingBySlot = (meeting: string, slot: string, token: string) => {
    const controller = loadAbort();

    return {
        call: axios.delete(
            `${process.env.REACT_APP_API_URL}/Events/${meeting}/slot/${slot}`,
            {headers: {Authorization: `Bearer ${token}`}, signal: controller.signal}),
        controller
    }
}
export const downloadDocumentBySlot = (meeting: string, token: string) => {
    const controller = loadAbort();

    return {
        call: axios.post(
            `${process.env.REACT_APP_API_URL}/Events/GeneratePdf/`+meeting,{},
            {headers: {Authorization: `Bearer ${token}`,'Content-Type': 'application/json',
            'Accept': 'application/pdf'}, signal: controller.signal,             responseType: 'arraybuffer',
        }),
        controller
    }
}
export const discardMeeting = (area: string, meeting: string, user: string, token: string) => {
    const controller = loadAbort();

    const body = {
        ServantArea: area,
        meetingID: meeting,
        servantID: user
    }

    return {
        call: axios.post(
            `${process.env.REACT_APP_MEETING_SERVICES}/DiscardMeeting`,
            body,
            {headers: {Authorization: `Bearer ${token}`}, signal: controller.signal}),
        controller
    }
}

export const assignMeeting = (isExpress: boolean, area: string, meeting: string, user: string, token: string) => {
    const controller = loadAbort();

    const body = {
        isExpressMeeting: isExpress,
        meetingArea: area,
        meetingID: meeting,
        servantIdToAssignMeeting: user,
    }

    return {
        call: axios.post(
            `${process.env.REACT_APP_MEETING_SERVICES}/AssignMeeting`,
            body,
            {headers: {Authorization: `Bearer ${token}`}, signal: controller.signal}),
        controller
    }
}

export const getMeetingsQueueStatus = (area: string, service: string, token: string) => {
    const controller = loadAbort();

    return {
        call: axios.get(
            `${process.env.REACT_APP_MEETING_SERVICES}/GetMeetingsQueueStatus/${area}/${service}`,
            {headers: {Authorization: `Bearer ${token}`}, signal: controller.signal}),
        controller
    }
}