import { Area } from "./Area";
import { Languages } from "./Languages";

export type OrganitzationInfo = {
  active: boolean;
  areas: Area[];
  code: string;
  displayName: string;
  email: string;
  languages: Languages[];
  phone: string;
  recordMeetingEnabled: boolean;
  showName: boolean;
  selectedServiceType: string;
  genericName: string;
  tenantCode: string;
  url: string;
};

export const defaultOrganitzationInfo = {
  active: false,
  areas: [],
  code: "",
  displayName: "",
  email: "",
  languages: [],
  phone: "",
  recordMeetingEnabled: false,
  showName: true,
  genericName: "",
  tenantCode: "",
  url: "",
  selectedServiceType: ""
};
