export const authConfig = {
  msal: {
    authority: process.env.REACT_APP_AUTHORITY,
    appId: 'e53d1c40-cfa9-49d6-a6f1-300811118331',
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    scopes: ['user.read'],
  },
    adfs: {
      identity: {
        authority: process.env.REACT_APP_AUTHORITY_ADFS,
        client_id: process.env.REACT_APP_AUTHORITY_ADFS_CLIENT_ID,
        redirect_uri: `${process.env.REACT_APP_REDIRECT_URI}/signin-callback.html`,
        post_logout_redirect_uri: process.env.REACT_APP_REDIRECT_URI,
        response_type: "id_token token",
        loadUserInfo: false,
        scope: "api://backoffice-api.in2tant.cloud",
        silent_redirect_uri: `${process.env.REACT_APP_REDIRECT_URI}/silent-renew.html`,
        automaticSilentRenew: true
      },
    }
}


        