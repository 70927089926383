import React, { useContext, FC } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Dropdown, Menu } from "semantic-ui-react";
import { AuthContext } from "../../context/AuthContext";
import { useTranslation } from "react-i18next";
import { logoutMeetingServant } from "../../assets/licences";
import LogoGeneralitat from "../../LogoGeneralitat.svg";
import "./layout.css";
import { getUsersArea } from "../../assets/api";
import { EnumRol } from "../../enum/EnumRol";
interface NavigationProps {
  userRoles: EnumRol[];
}

const Navigation: FC<NavigationProps> = ({ userRoles }: NavigationProps) => {
  const authContext = useContext(AuthContext);
  const { t } = useTranslation();
  const history = useHistory();

  const updateStatus = async (status: string) => {
    await authContext.onAccountStatus(status);
  };

  const updateLicence = async () => {
    await logoutMeetingServant(JSON.parse(authContext.licence));
    
    const cookies = document.cookie.split(';');
    cookies.map(cookie => {
      document.cookie = `${cookie}=;expires=${new Date(0).toUTCString()}`
    })

    authContext.onSignOut();
  };

  const handleRol = (adminRoles: EnumRol[]) => {
    let response = false;
    adminRoles.forEach((admin) => {
      if (userRoles.includes(admin)) {
        response = true;
      }
    });
    return response;
  };

  const getUsername = async () => {
    const { userName } = await authContext
      .getAccount()
      .then((response) => JSON.parse(response));
    return userName;
  };

  const MenuLinkList = [
    { to: "/", text: t("menu.home"), isVisible: true },
    {
      to: "/area",
      text: t("menu.area"),
      isVisible: handleRol([EnumRol.AreaAdmin]),
    },
    {
      to: "/organitzation",
      text: t("menu.organitzation"),
      isVisible: handleRol([EnumRol.OrganizationAdmin]),
    },
    {
      to: "/administration",
      text: t("menu.administration"),
      isVisible: handleRol([EnumRol.TenantAdmin]),
    },
    {
      to: "/management",
      text: t("menu.management"),
      isVisible: handleRol([EnumRol.CrossReader]),
    },
  ];

  return (
    <Menu stackable>
      <Menu.Item>
        <img src={LogoGeneralitat} style={{ width: 150 }} />
      </Menu.Item>
      {MenuLinkList.map(({ to, text, isVisible }) => (
        <>
          {isVisible ? (
            <Menu.Item>
              <button
                style={{
                  backgroundColor: "transparent",
                  border: "0px solid transparent",
                }}
                onClick={() => {
                  history.push(to);
                }}
              >
                <p style={{ color: "white" }}>{text}</p>
              </button>
            </Menu.Item>
          ) : (
            false
          )}
        </>
      ))}

      <Menu.Menu position="right">
        <Menu.Item>
          {authContext.accountStatus !== undefined ? (
            <div style={{ display: "flex" }}>
              {authContext.accountStatus === "Available" && (
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    backgroundColor: "green",
                  }}
                ></div>
              )}
              {authContext.accountStatus === "Inactive" && (
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    backgroundColor: "orange",
                  }}
                ></div>
              )}
              {authContext.accountStatus === "Busy" && (
                <div
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "50%",
                    backgroundColor: "red",
                  }}
                ></div>
              )}
              <div style={{ marginLeft: "1em", lineHeight: "1.4em" }}>
                {authContext.account.profile.name}
              </div>
            </div>
          ) : (
            ""
          )}
        </Menu.Item>
        <Dropdown item icon={{ name: "user" }}>
          <Dropdown.Menu>
            <Dropdown.Item>
              <Dropdown text={t("menu.user.status")} direction="left">
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => updateStatus("Available")}>
                    <div
                      style={{
                        display: "inline-block",
                        marginRight: "0.5em",
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "green",
                      }}
                    ></div>{" "}
                    {t("menu.user.available")}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => updateStatus("Inactive")}>
                    <div
                      style={{
                        display: "inline-block",
                        marginRight: "0.5em",
                        width: "10px",
                        height: "10px",
                        borderRadius: "50%",
                        backgroundColor: "orange",
                      }}
                    ></div>{" "}
                    {t("menu.user.absent")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Dropdown.Item>
            <Dropdown.Item onClick={() => updateLicence()}>
              {t("menu.user.sign_out")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Menu>
    </Menu>
  );
};

export default Navigation;
