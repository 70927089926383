import { current } from "@reduxjs/toolkit";
import React, { FC, Fragment, ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, List as ListUI, Popup, Table } from "semantic-ui-react";
import { EnumMeetingStatus } from "../../../../../../enum/EnumMeetingStatus";
import {
  defaultEventMeeting,
  EventMeeting,
} from "../../../../../../models/EventMeeting";
import ModalDiscardMeeting from "../../../../../../pages/Dashboard/components/Modals/ModalDiscardMeeting";
import BOModalDeleteMeeting from "../../../BOModalDeleteMeeting";
import ActionsCell from "./components/ActionsCell";
import AreaCell from "./components/AreaCell";
import AssignedCell from "./components/AssignedCell";
import AttendeeCell from "./components/AttendeeCell";
import CodeCell from "./components/CodeCell";
import IsQueuedCell from "./components/IsQueuedCell";
import ServiceCell from "./components/ServiceCell";
import StatusCell from "./components/StatusCell";
import { CONTENT_MEETING_TYPE } from "./type/contentMeeting";
import { MODAL_TYPES } from "./type/modalTypes";

interface BORowMeetingProps {
  meetings: EventMeeting[];
  contentMeeting: (string | any)[];
  onClickMeeting?: (x: any) => void;
  disabledMeeting?: (x: any) => boolean;
  servicesRefresh?: () => void;
}

const BORowMeeting: FC<BORowMeetingProps> = (
  props: BORowMeetingProps
): ReactElement => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState<any>({ type: "", isOpen: false });
  const [meetingSelected, setMeetingSelected] =
    useState<any>(defaultEventMeeting);
  const handleOpenModalDelete = (value: boolean) => {
    setOpenModal({
      type: MODAL_TYPES.DELETE,
      isOpen: value,
    });
  };

  const handleOpenModalRefuse = (value: boolean) => {
    setOpenModal({
      type: MODAL_TYPES.REFUSE,
      isOpen: value,
    });
  };

  const handleMeetingSelected = (value: EventMeeting) => {
    setMeetingSelected(value);
  };

  const renderCellMeeting = (
    meeting: any,
    typeParam: any,
    isDisabled: boolean | undefined,
    timeStatus: any,
    index?: number
  ) => {
    const type = typeof typeParam === typeof "" ? typeParam : typeParam.type;
    let value = meeting[type];

    if (type === "code") {
      value = (
        <CodeCell index={index} attendees={meeting} trigger={value} />
      );
    } else if (type === "status") {
      if(timeStatus.negative) {
        if (value === EnumMeetingStatus.CITIZEN_WAITING || value === 'Ciutadà en espera' || value === 'Citizen Waiting' || value === 'Ciudadano Esperando' ||
          value === EnumMeetingStatus.SCHEDULED_PROGRAMMED || value === 'Programat' || value === 'Scheduled' || value === 'Programada' ||
          value === EnumMeetingStatus.SCHEDULED_CUSTOM ||
          value === EnumMeetingStatus.WAITING_FOR_CITIZEN || value === 'Esperant Ciutadà' || value === 'Waiting for the Citizen' || value === 'Esperando al Ciudadano') {
          value = EnumMeetingStatus.NOT_ATTENDEED;

      }
        
        /*if(value === EnumMeetingStatus.IN_PROGRESS ||
             value === 'En Progreso' ||
             value === 'En progrés'){
              value = EnumMeetingStatus.IN_PROGRESS
          }*/
          
          
      }

      value = <StatusCell index={index} meeting={meeting} trigger={value} />;
    } else if (type === "action") {
      value = (
        <ActionsCell
          contentType={typeParam.content}
          meeting={meeting}
          isDisabled={isDisabled}
          timeStatus={timeStatus}
          setOpenModal={setOpenModal}
          servicesRefresh={props.servicesRefresh}
          handleMeetingSelected={handleMeetingSelected}
        />
      );
    } else if (type === CONTENT_MEETING_TYPE.SERVICE_CODE) {
      value = <ServiceCell serviceCode={value} />;
    } else if (type === CONTENT_MEETING_TYPE.AREA_CODE) {
      value = <AreaCell value={meeting.serviceCode} />;
    } else if (type === CONTENT_MEETING_TYPE.ASSIGNED) {
      value = <AssignedCell value={value} meeting={meeting} />;
    } else if (type === CONTENT_MEETING_TYPE.ATTENDEE) {
      value = <AttendeeCell value={value} />;
    } else if (type === "isQueued") {
      value = <IsQueuedCell timeStatus={timeStatus} value={value} />;
    }
    return (
      <Table.Cell
        className={"align-middle"}
        onClick={() => {
          type !== "action" &&
            !isDisabled &&
            !timeStatus.negative &&
            props.onClickMeeting &&
            props.onClickMeeting(meeting);
        }}
      >
        {value}
      </Table.Cell>
    );
  };

  const getMeetingStart = ( meeting: EventMeeting) => {
    if( meeting.MeetingStart !== undefined )
      return meeting.MeetingStart

    if(!window.location.href.includes('search')){
      const day = meeting.day
      const hour = meeting.start;
  
      let rest = hour.split(':');
      rest[0] = rest[0] - 1;

      const year = day?.slice(0,4);
      const month = day?.slice(4,6);
      const date = day?.slice(6,8);
      console.log("DATA_NOW", date, ' ', month, ' ', year);
  
      return `${year}-${month}-${date}T${(rest[0] < 10 ? '0' + rest[0] : rest[0])}:${rest[1]}:00.000Z`
    }else{
      console.log("DATA_NOW", meeting.start);
      return meeting.start
    }
  }

  const isMeetingCancelled = (status: string) => {
    const cancelled = ['Cancel', 'Cancel·lat', 'Cancelada'];

    return cancelled.some(cancel => status.includes(cancel));
  }

  return (
    <Fragment>
      {props.meetings.length > 0 ? (
        props.meetings.map((meeting: EventMeeting, index) => {
          const isDisabled = props.disabledMeeting && props.disabledMeeting(meeting);
          const meetingStart = new Date(getMeetingStart(meeting)); 
          const meetingEnd = new Date(meeting.end);
          const currentDate = new Date();

          const min = meetingStart.getMinutes();
          const minEnd = meetingEnd.getMinutes();

          meetingStart.setMinutes(min - 10);
          meetingEnd.setMinutes(minEnd + 20);

          const timeStatus = {
            positive: (currentDate >= meetingStart && currentDate < meetingEnd),
            negative: currentDate > meetingEnd
          }

          return (
            <Table.Row
              key={"m-" + index}
              className="table-meetings"
              style={{
                cursor:
                  !isDisabled && props.onClickMeeting ? "pointer" : "auto",
              }}
              positive={timeStatus.positive && !isMeetingCancelled(meeting.status)}
              negative={timeStatus.negative || isMeetingCancelled(meeting.status)}
            >
              {props.contentMeeting.map((_: string) =>
                renderCellMeeting(meeting, _, isDisabled, timeStatus, index)
              )}
            </Table.Row>
          );
        })
      ) : (
        <Table.Row key={"c-no"}>
          <Table.Cell>No hay citas para el día de hoy</Table.Cell>
        </Table.Row>
      )} 
      {openModal.type === MODAL_TYPES.DELETE && (
        <BOModalDeleteMeeting
          onOpen={openModal.isOpen}
          handleOpen={handleOpenModalDelete}
          meeting={meetingSelected}
          servicesRefresh={props.servicesRefresh}
        />
      )}
      {openModal.type === MODAL_TYPES.REFUSE && (
        <ModalDiscardMeeting
          setModal={handleOpenModalRefuse}
          meeting={meetingSelected}
          modal={openModal.isOpen}
          onAccept={props.servicesRefresh}
        // headerText={modal.headerText}
        />
      )}  
    </Fragment>
  );
};
export default BORowMeeting;
