import { shouldWrapFocus } from "office-ui-fabric-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, Form } from "semantic-ui-react";
import RichTextEditorField from "./RichTextEditorField";
import TextField from "./TextField";
interface Props {
  organitzationURL: any;
  setOrganitzationURL: any;
  isRecording: any;
  updateRecordingsForOrganization: any;
  organitzationURLInformers: any;
  setOrganitzationURLInformers: any;
  updateInformersNamesForArea: any;
  showName: any;
  setGenericInformersName: any;
  genericInformersName: any;
}

export default (props: Props) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  return (
    <div>
      <TextField
        title={t("organitzation.personalitzation.url")}
        value={props.organitzationURL}
        onChange={(smsWithLinktoEvent: any) =>
          props.setOrganitzationURL(smsWithLinktoEvent)
        }
        id="entityOrganitzationURL"
      />
      <TextField
        title={t("organitzation.personalitzation.url_informers")}
        value={props.organitzationURLInformers}
        onChange={(smsWithLinktoEvent: any) =>
          props.setOrganitzationURLInformers(smsWithLinktoEvent)
        }
        id="entityOrganitzationURL"
      />

      <Checkbox
        label={t("organitzation.personalitzation.informators_names")}
        defaultChecked={props.showName}
        onChange={props.updateInformersNamesForArea}
      />

      <TextField
        title={t("organitzation.personalitzation.title_informators")}
        value={props.genericInformersName}
        onChange={(informers_name: any) =>
          props.setGenericInformersName(informers_name)
        }
        id="genericInformersName"
      />

      {show ?
        <Form.Field>
          <label style={{ marginBottom: "10px", marginTop: "10px" }}>
            {t("organitzation.personalitzation.recording")}
          </label>
          <Checkbox
            toggle
            defaultChecked={props.isRecording}
            onChange={props.updateRecordingsForOrganization}
          />
        </Form.Field> : null
      }
    </div>
  );
};
