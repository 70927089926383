import moment from "moment"
import { EEventInTime, EMeetingStatus, IEventDetailAdapter, IEventDetailResponse } from "../models/event.models"

export const createEventDetailAdapter = (event: IEventDetailResponse, area: string, assignedTo?: string): IEventDetailAdapter => {
    return {
        Area: area,
        AssignedTo: assignedTo || "",
        Attendees: createAttendeeAdapter(event.attendees),
        ChangeHistory: createChangeHistoryAdapter(event.changeHistory),
        Comment: event.comment || "",
        End: event.end,
        Code: event.code,
        OtpAvailable: event.isOtpAvailable,
        Service: event.serviceCode,
        Slot: event.slotId,
        Start: event.start,
        Status: getStatus(event.status, event.start, event.end),
        InTime: getInTimeStatusFromDates(event.start, event.end),
        Subject1: createSubjectAdapter(event.subjects1),
        Subject2: createSubjectAdapter(event.subjects2),
        Subject3: createSubjectAdapter(event.subjects3),
        Observations: event.observations || ""
    }
}

const createAttendeeAdapter = (attendees: any[]) => {
    return attendees.map(attendee => {
        return {
            Name:   attendee.fullName,
            Phone:  attendee.phone,
            Mail:   attendee.email,
            ID:     attendee.personId
        }
    })
}

const createChangeHistoryAdapter = (changeHistory: any[]) => {
    return changeHistory.map(history => {
        return {
            Date: history.date,
            Type: history.changeType,
            Info: history.changeInfo
        }
    })
}

const createSubjectAdapter = (subjects: any[] = []) => {
    return subjects.map(sub => {
        return {
            code: sub.code,
            description: sub.description
        }
    });
}

const getInTimeStatusFromDates = (start: Date, end: Date) => {
    const now = moment().format("YYYY-MM-DD HH:mm:ss");
    const startMoment = moment(start).format("YYYY-MM-DD HH:mm:ss");
    const endMoment = moment(end).format("YYYY-MM-DD HH:mm:ss");

    if( startMoment > now && endMoment > now) return EEventInTime.EarlyAccess;
    if( endMoment < now ) return EEventInTime.LaterAccess;

    return EEventInTime.InTime
}

const getStatus = (status: string, start: Date, end: Date) => {
    const time: EEventInTime = getInTimeStatusFromDates(start, end);

    if (status === "CitizenWaiting" || status === "ScheduledProgrammed" || status === "ScheduledCustom" || status === "WaitingForCitizen") {
        if(time === EEventInTime.LaterAccess) return EMeetingStatus.NOT_ATTENDEED
        return Object.values(EMeetingStatus).find(value => value === status) || EMeetingStatus.CANCELLED
    }
    else {
        return Object.values(EMeetingStatus).find(value => value === status) || EMeetingStatus.NOT_ATTENDEED; // Asignar un valor por defecto si no se encuentra el estado
    }
}