import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import useFetchAndLoad from "../../../hooks/useFetchAndLoad";

import { Button, Grid, Icon, Input, Table } from "semantic-ui-react";
import Modal from "../../../components/Modal/Modal";
import React from "react";
import { IUsersAdapter } from "../../../models/user.models";
import { getUsersFromArea, updateUsersService } from "../controllers/area_service_details.controller";
import { info } from "../../../adapters";

interface IProps{
    area: string,
    service: string,
    backoffice: string,
    open: boolean,
    setOpen: any,
    setReloadUsers: any,
    users: IUsersAdapter[],
    usersArea: IUsersAdapter[],
}

const AreaAdministrationServiceDetailsAddEmployeesModal = ({ area, service, backoffice, open, setOpen, setReloadUsers, users, usersArea }: IProps) => {
    
    const dispatch = useDispatch();
    const { callEndpoint } = useFetchAndLoad();
    const { t } = useTranslation();

    const [ usersToAdd, setUsersToAdd ] = useState<IUsersAdapter[]>([]);

    const [ usersFilter, setUsersFilter ] = useState<IUsersAdapter[]>([]);
    const [ usersFilterArea, setUsersFilterArea ] = useState<IUsersAdapter[]>();

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title={ t('detail.modal.title') }
            content={
                <>
                    <Grid divided="vertically">
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <p><b>{ t('detail.modal.users_area') }</b></p>
                                <Input fluid placeholder="Buscar Funcionario" type="text" onChange={(e) => {setUsersFilterArea(usersArea?.filter(user => user.Name.toLowerCase().includes(e.target.value.toLocaleLowerCase())))}}/>
                                <Table basic="very">
                                    <Table.Body>
                                        { usersFilterArea !== undefined ? 
                                            <>
                                                {usersFilterArea.map(user => 
                                                    <Table.Row>
                                                        <Table.Cell onClick={() => {
                                                            if(!usersToAdd.find(userToAdd => userToAdd.Code === user.Code)){
                                                                setUsersToAdd(older => [user, ...older])
                                                            }
                                                        }}>
                                                            { user.Name }
                                                        </Table.Cell>
                                                    </Table.Row>    
                                                )}
                                            </>
                                        :
                                            <>
                                                { usersArea !== undefined && 
                                                    <>
                                                        {usersArea.map(user => 
                                                            <Table.Row>
                                                                <Table.Cell onClick={() => {
                                                                    if(!usersToAdd.find(userToAdd => userToAdd.Code === user.Code)){
                                                                        setUsersToAdd(older => [user, ...older])
                                                                    }
                                                                }}>
                                                                    { user.Name }
                                                                </Table.Cell>
                                                            </Table.Row>    
                                                        )}
                                                    </>
                                                }
                                            </>                                        
                                        }
                                    </Table.Body>
                                </Table>
                            </Grid.Column>
                            <Grid.Column>
                                <p><b>{ t('detail.modal.users_service') }</b></p>
                                <Input fluid placeholder="Buscar Funcionario" type="text" onChange={(e) => {setUsersFilter(users.filter(user => user.Name.toLowerCase().includes(e.target.value.toLocaleLowerCase())))}}/>
                                <Table basic="very">
                                    <Table.Body>
                                        { usersFilter !== undefined && usersFilter.length > 0 ?
                                            <>
                                                {usersFilter?.map(user => 
                                                    <Table.Row>
                                                        <Table.Cell>{ user.Name } </Table.Cell>
                                                    </Table.Row>    
                                                )}
                                            </>

                                        :
                                            <>
                                                {users?.map(user => 
                                                    <Table.Row>
                                                        <Table.Cell>{ user.Name } </Table.Cell>
                                                    </Table.Row>    
                                                )}
                                            </>
                                        }
                                    </Table.Body>
                                </Table>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <hr />
                    <p><b>{ t('detail.modal.users_add') }</b></p>
                    { usersToAdd !== undefined && usersToAdd.length > 0 ? (
                        <>
                            { usersToAdd?.map(user => { 
                                return (
                                    <Button 
                                        icon
                                        labelPosition='right'
                                        color="green"
                                        onClick={() => setUsersToAdd(older => older.filter(list => list.Code !== user.Code))}
                                        >
                                        { user.Name }
                                        <Icon name='times' />
                                    </Button>
                                ) 
                            })}
                        </>
                    ) : (
                        <span>{ t('detail.notselected_users') }</span>
                    )}
                </>
            }
            actions={
                <>
                    <Button color="grey" onClick={() => setOpen(false)}>{ t('detail.btn_cancel') }</Button>
                    <Button color="red" onClick={() => {
                        updateUsersService(service, usersToAdd, backoffice, dispatch, callEndpoint);
                        setUsersToAdd([]);
                        setReloadUsers(true);
                        setOpen(false);
                    }}>{ t('detail.btn_add_users') }</Button>
                </>
            }
        />
    )
}

export default AreaAdministrationServiceDetailsAddEmployeesModal;