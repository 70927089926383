import React from "react";
import { useTranslation } from "react-i18next";
import { Button, List as ListUI, Popup, Table } from "semantic-ui-react";
import Text from "../atomic_components/Text";
interface Props {
  index?: number;
  attendees: any[];
  trigger: string;
}
const getAttendee = (props: any) => {
  if (props.attendees && props.attendees.attendees && props.attendees.attendees.length > 0) {
    const { fullName, personId, phone, email } = props.attendees.attendees[0];
    const { observations } = props.attendees;
    let { subjects1, subjects2, subjects3 } = props.attendees;

    if (Array.isArray(subjects1) && subjects1.length > 0) {
      subjects1 = subjects1[0].description;
    } else {
      subjects1 = ''; 
    }

    if (Array.isArray(subjects2) && subjects2.length > 0) {
      subjects2 = subjects2[0].description;
    } else {
      subjects2 = ''; 
    }

    if (Array.isArray(subjects3) && subjects3.length > 0) {
      subjects3 = subjects3[0].description;
    } else {
      subjects3 = '';
    }

    return {
      fullName,
      personId,
      phone,
      email,
      observations,
      subjects1,
      subjects2,
      subjects3
    };
  } else {
    return {};
  }
};


const CodeCell = (props: Props) => {
  const { t } = useTranslation();
  return (
    <>
      {/* <p>{props.trigger}</p> */}
      {Object.entries(getAttendee(props)).length != 0 ? (
        <Popup
          key={props.index}
          trigger={<p>{props.trigger}</p>}
          position={"right center"}
        >
          {Object.entries(getAttendee(props)).length != 0 && (
            <>
              <Popup.Header>
                {t("detail.meeting.details_code.meeting_data")}
              </Popup.Header>
              <Popup.Content>
                <ListUI>
                  {Object.entries(getAttendee(props)).map(([key, value]) => (
                    (((key === "subjects1" || key === "subjects2" || key === "subjects3") && (value && value.length > 0)) ||  (key !== "subjects1" && key !== "subjects2" && key !== "subjects3")) && (
                    //((key !== "subjects1" && key !== "subjects2" && key !== "subjects3" && value !== "")) && (
                    //((key !== "subjects1" && key !== "subjects2" && key !== "subjects3" && value !== "") ||  ((key === "subjects1" && value !== "" ) || (key === "subjects2" && value !== "" ) || (key === "subjects3" && value !== "" ))) && (
                    <ListUI.Item>
                      <span className={"font-weight-bold"}>{`${t(
                        `detail.meeting.details_code.${key}`
                      )}:    `}</span>
                      {value}
                    </ListUI.Item> )
                  ))}
                </ListUI>
              </Popup.Content>
            </>
          )}
        </Popup>
      ) : (
        <Text>{props.trigger}</Text>
      )}
    </>
  );
};
export default CodeCell;
