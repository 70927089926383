import React from "react";
import { useTranslation } from "react-i18next";
import RichTextEditorField from "./RichTextEditorField";
import TextField from "./TextField";
interface Props {
  emailEventFields: any;
  onChangeFields: any;
}

export default (props: Props) => {
  const { t } = useTranslation();
  return (
    <div>

      <TextField
        title={t("detail.personalitzation.email_confirmation_subject")}
        value={props.emailEventFields.emailEventConfirmationSubject}
        onChange={(emailEventConfirmationSubject: any) =>
          props.onChangeFields({ emailEventConfirmationSubject })
        }
        id="entityEmailConfirmationSubject"
      />

      <RichTextEditorField
        title={t("detail.personalitzation.email_confirmation")}
        value={props.emailEventFields.emailEventConfirmation}
        onEditorChange={(emailEventConfirmation: any) =>
          props.onChangeFields({ emailEventConfirmation })
        }
        id={"entityEmailConfirmationBody"}
      />
      

      <TextField
        title={t("detail.personalitzation.email_link_subject_express")}
        value={props.emailEventFields.emailWithLinktoEventExpressSubject}
        onChange={(emailWithLinktoEventExpressSubject: any) =>
          props.onChangeFields({ emailWithLinktoEventExpressSubject })
        }
        id="emailWithLinktoEventExpressSubject"
      />
      <RichTextEditorField
        title={t("detail.personalitzation.email_link_express")}
        value={props.emailEventFields.emailWithLinktoEventExpress}
        onEditorChange={(emailWithLinktoEventExpress: any) =>
          props.onChangeFields({ emailWithLinktoEventExpress })
        }
        id={"entityEmailLinkExpressBody"}
      />

      <TextField
        title={t("detail.personalitzation.email_link_subject")}
        value={props.emailEventFields.emailWithLinktoEventSubject}
        onChange={(emailWithLinktoEventSubject: any) =>
          props.onChangeFields({ emailWithLinktoEventSubject })
        }
        id="emailWithLinktoEventSubject"
      />
      <RichTextEditorField
        title={t("detail.personalitzation.email_link")}
        value={props.emailEventFields.emailWithLinktoEvent}
        onEditorChange={(emailWithLinktoEvent: any) =>
          props.onChangeFields({ emailWithLinktoEvent })
        }
        id={"entityEmailLinkBody"}
      />

      <TextField
        title={t("detail.personalitzation.email_cancelation_subject")}
        value={props.emailEventFields.emailEventCancelationSubject}
        onChange={(emailEventCancelationSubject: any) =>
          props.onChangeFields({ emailEventCancelationSubject })
        }
        id="entityEmailCancelationSubject"
      />
      <RichTextEditorField
        title={t("detail.personalitzation.email_cancelation")}
        value={props.emailEventFields.emailEventCancelation}
        onEditorChange={(emailEventCancelation: any) =>
          props.onChangeFields({ emailEventCancelation })
        }
        id={"entityEmailCancelation"}
      />
      <RichTextEditorField
        title={t("detail.personalitzation.email_otp")}
        value={props.emailEventFields.emailWithOTPBody}
        onEditorChange={(emailWithOTPBody: any) =>
          props.onChangeFields({ emailWithOTPBody })
        }
        id={"emailWithOTPBody"}
      />

      <TextField
        title={t("organitzation.personalitzation.video_reminder_subject")}
        value={props.emailEventFields.emailEventVideoReminderSubject}
        onChange={(emailEventVideoReminderSubject: any) =>
          props.onChangeFields({ emailEventVideoReminderSubject })
        }
        id="entityEmailVideoReminderSubject"
      />

      <RichTextEditorField
        title={t("organitzation.personalitzation.video_reminder")}
        value={props.emailEventFields.emailEventVideoReminder}
        onEditorChange={(emailEventVideoReminder: any) =>
          props.onChangeFields({ emailEventVideoReminder })
        }
        id={"entityEmailVideoReminderBody"}
      />

    </div>
  );
};
